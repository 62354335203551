<template>
    <div>

      
        <!-- doutor_selecionado: {{doutor_selecionado}} <br/><br/> -->
        <!-- procedimento_selecionado: {{procedimento_selecionado}} <br/><br/> -->
        <!-- obj_todos_convenios: {{obj_todos_convenios}} <br/><br/> -->

        <!-- superUser:{{superUser}}<br/><br/> -->
<!-- {{ clicou_em }} -->
<!-- {{ referencia }} -->

<!-- item_edita:{{  item_edita}} <br/><br/> -->
<!-- {{ urlLogo }} -->
<!-- {{ urlImages }} -->
      <!-- {{ table_codigo }} -->
      <!-- {{ session_bd}} -->
      <!-- {{ user_permissoes }} -->
      <!-- {{ session_bd }} -->
<!-- {{ this.today }} -->
<!-- tabela_selecionada:{{ tabela_selecionada }} <br/><br/> -->
<!-- items_lista:{{ items_lista }} <br/><br/> -->
<!-- <br/> -->
<!-- {{ obj_clipboard }} -->
      <!-- DSI -->
      <!-- {{ selected }} -->
      <!-- {{ obj_clicado }} -->
      <!-- {{ obj_copiar }} -->

      <v-overlay :value="overlay">
        <v-progress-circular
          indeterminate
          size="64"
        ></v-progress-circular>
      </v-overlay>


      <v-snackbar
          v-model="snackbar"
          :timeout="2000"
          top
          :color="snack_color"    
      >
        <center>
          {{ snack_text }}
        </center>
      </v-snackbar>
  


  <!-- MENU -->
  <v-dialog
      v-model="dialog_menu_right"
      max-width="300px"
      scrollable
    >
        <v-card
            class="mx-auto"
        >
            <v-list>

            <v-subheader class="grey lighten-5">
             <h4>Opções</h4>
             </v-subheader>

            <v-divider
                class="mx-0"
            ></v-divider>

            <v-list-item
             link 
             @click="dialog_menu_right=false, copiarObj()"           
            >
                <v-list-item-icon>
                <v-icon color="primary">mdi-content-copy </v-icon>
                </v-list-item-icon>
                <v-list-item-title>Copiar</v-list-item-title>
            </v-list-item>
            
            <v-divider
                class="mx-0"
            ></v-divider>

            <v-list-item
             link 
             @click="dialog_menu_right=false, to_clipboard(items_lista,'obj')"     
             v-if="(superA)"    
            >
                <v-list-item-icon>
                <v-icon color="success">mdi-content-copy </v-icon>
                </v-list-item-icon>
                <v-list-item-title>Copiar Tudo </v-list-item-title>
            </v-list-item>

            <v-divider
                class="mx-0"
            ></v-divider>

            <v-list-item
             link 
             @click="dialog_menu_right=false, colar_objetos(tabela_selecionada,'clip')"     
             v-if="(superA)"      
            >
                <v-list-item-icon>
                <v-icon color="success"> mdi-content-duplicate  </v-icon>
                </v-list-item-icon>
                <v-list-item-title>Colar Tudo </v-list-item-title>
            </v-list-item>

            <v-divider
                class="mx-0"
            ></v-divider>

            <v-list-item
             link 
             @click="dialog_menu_right=false, excluir_tudo(tabela_selecionada)"     
             v-if="(superA)"      
            >
                <v-list-item-icon>
                <v-icon color="red"> mdi-delete  </v-icon>
                </v-list-item-icon>
                <v-list-item-title> Excluir Tudo </v-list-item-title>
            </v-list-item>

            </v-list>
        </v-card>
  </v-dialog>  


      <v-dialog
          v-model="dialog_tabela"
          persistent
          max-width="990"
          scrollable
          :fullscreen="vai_imprimir"
      >
  
          <v-card
          class=""
          >
          <!-- height="80%" -->
          <v-card-title>
              <span class="text-h5" 
                v-text="(item_edita.CODIGO>0) ? 'Alterar '+ card_titulo +' '+item_edita.CODIGO : 'Novo '+ card_titulo "
              >
              Alterar
             </span>
          </v-card-title>
  
  

              <!-- passo 4 monto os campos editaveis -->
              <!-- :readonly = "readonly" -->





                  <v-card-text class="pa-1">
                    <v-form
                      ref="form"
                      v-model="valido"
                      >
                      <v-container>
                          <v-row>
                              <v-col
                                  class=""
                                  v-for="(item, i) in items_editaveis"
                                  :key="i"
                                  :cols="(isMobile) ? 12 : item.cols"
                                  v-if="parseInt(item.cols)>0"   
                              >
                              <!-- :cols="item.cols" -->




                                <v-card  class="" 
                                  v-if="(item.tag == 'v-parametros')"
                                >
                                  <v-card-text>

                                    <h3 class="blue--text"> {{ item.text }} </h3>
                                    
                                    <v-chip-group
                                      column 
                                    >
                                      <v-chip
                                          v-for="(item, i) in item.value"
                                          :key="i"
                                          
                                          small
                                          @click="insere_tag(item)"
                                      >
                                        {{ item }}

                                        <span v-if="(item=='@::)')"> ☺️ </span>
                                        <span v-if="(item=='@;)')"> 😉 </span>
                                        <span v-if="(item=='@:)')"> 🙂 </span>
                                        <span v-if="(item=='@[]')"> 👩🏼‍💻 </span>
                                        
                                      </v-chip>
                                    </v-chip-group>
                                  </v-card-text>
                                  </v-card>



                                 <v-text-field 
                                      dense
                                      v-model="item_edita[item.value]"
                                      :label="item.rule == 'S' ? item.text+' *': item.text"
                                      :type="item.type"
                                      v-if="(item.tag == 'v-text') && (item.vmask)"
                                      @keyup="meu_keyup($event, item.value, item.upper)"
                                      :rules="item.rule == 'S' ? notnullRules: noRules"
                                      :autofocus="i==1"
                                      :hint="item.hint"
                                      v-mask = "getVmask(item.vmask)"
                                  ></v-text-field>
                                  
                                  <!-- SEM V-MASK  acaba dando erro, por isso separei-->
                                  <v-text-field
                                      dense
                                      v-model="item_edita[item.value]"
                                      :label="item.rule == 'S' ? item.text+' *': item.text"
                                      :type="item.type"
                                      v-if="(item.tag == 'v-text') && (!item.vmask)"
                                      @keyup="meu_keyup($event, item.value, item.upper)"
                                      :rules="item.rule == 'S' ? notnullRules: noRules"
                                      :autofocus="i==1"
                                      :hint="item.hint"
                                  ></v-text-field>

                                  
                                  <v-combobox
                                      dense
                                      v-model="item_edita[item.value]"
                                      :label="item.text"
                                      v-if="item.tag == 'v-autocomplete'"
                                      :items="item.tag_items"
                                      :rules="item.rule == 'S' ? notnullRules: noRules"
                                  ></v-combobox>
                                  
                                  <v-select
                                      :class="[item.class]"
                                      dense
                                      v-model="item_edita[item.value]"
                                      :label="item.text"
                                      v-if="item.tag == 'v-select'"
                                      :items="item.tag_items"
                                      :rules="item.rule == 'S' ? notnullRules: noRules"

                                      item-value='CODIGO'
                                      item-text='NOME'
                                      :clearable = "item.tag_clear == 'S'"
                                      :persistent-hint="item.cols>2"
                                      :hint="item.hint"
                                  >
                                  
                                  <!-- <template slot="selection" v-slot-scope="item"> -->
                                    
                                  <!-- <template slot="selection" v-slot-scope="item"> -->
                                    <template v-slot:label>
                                      <v-tooltip bottom v-if="item.hint">
                                          <template v-slot:activator="{ on, attrs }">
                                            <span
                                                v-bind="attrs"
                                                v-on="on"
                                            > 
                                              <span :class="item.class">
                                                {{ item.text }} 
                                                <!-- {{ item_edita[item.value] }} -->
                                              </span>
                                            </span>

                                          </template>
                                          <span> {{ item.hint }} </span>
                                        </v-tooltip>
                                    </template>
       
                                </v-select>


                              <!-- CAMPO ANOTAÇÕES OU OBSERVAÇÃO -->
                              <!-- <component :is="(vai_imprimir) ? 'v-text' : 'v-textarea' "   -->
                              <v-textarea
                                v-model="item_edita[item.value]"
                                :label="item.text"
                                v-if="item.tag == 'v-textarea'"
                                :hint="item.hint"
                                :persistent-hint="item.cols>2"
                                dense
                                :rows="item.rows"
                                :filled="item.solo=='S'"
                                :autofocus="i==2"
                                :rules="item.rule == 'S' ? notnullRules: noRules"
                                @click="clicou_em=item.value"
                                id="textara_to_print"
                                ref="textara_to_print"
                              >
                              <!-- vai_imprimir -->
                              </v-textarea>




                                  <!-- INICIO DIV IMAGEM -->
                                  <div class=""
                                   v-if="item.tag == 'img'"
                                  >
                                  
                                  <v-row dense class="grey lighten-5">
                                      <v-col  class="">
                                        <span class="pb-2">Logo</span>
                                          <v-img
                                          v-if="urlLogo"
                                          max-height="90"
                                          max-width="170"
                                          :src="urlImages + item_edita['PATH_LOGO']"
                                          contain
                                          eager
                                        >
                                        </v-img>
                                        <!-- {{ item_edita['PATH_LOGO'] }} -->
                                      </v-col>
                                      
                                      <!-- <v-spacer></v-spacer> -->

                                      <v-col  class="text-center mt-5">
                                        <p class="primary--text"><label for="file_upload" style="cursor: pointer;">Alterar Logo</label></p>
                                        <!-- <p class="primary--text"><label for="file_upload" style="cursor: pointer;">Alterar Logo</label></p> -->
                                        <!-- <p>Remover</p> -->
                                        <v-btn text x-small color="red" for="file_upload"
                                          @click="item_edita[item.value] = '', urlLogo=''"
                                        >
                                          Remover
                                        </v-btn>
                                      </v-col>


                                    </v-row>
                                    <!-- oculto -->
                                    <v-row dense class="grey lighten-3">
                                      <v-col>
                                        <!-- necessario para fazer upload logo -->
                                        <v-file-input
                                          v-model="files_upload"
                                          accept="image/*"
                                          label="Logomarca"                                      
                                          @change="upload_logo(files_upload, item.folder , (item.img_name_field!='') ?  item.img_name +'_'+ item_edita[item.img_name_field] :item.img_name ,item.value)"
                                          style="display: none"
                                          id="file_upload"
                                        >
                                        <!-- @change="upload_logo(files_upload, session_bd ,'logo',item.value)" -->
                                        <!-- session_bd = nome da pasta onde salvar as imagens..peguei pelo nome do banco de dados -->
                                        </v-file-input>
                                        <!-- necessario para fazer upload logo -->
                                      </v-col>
                                    </v-row>
                                  </div>
                                  <!-- FINAL DIV IMAGEM -->


                                  
                                  
                              </v-col>
                          </v-row>
                      </v-container>
                    </v-form>
                  </v-card-text>
  
          <v-card-actions>
              <v-btn
                  x-small
                  color="red"
                  text
                  @click="remover_tabela(tabela_selecionada, item_edita, card_titulo), dialog_tabela = false"
                  v-if="permissoes(tabela_selecionada, 'alterar')"
              >
                Excluir
              </v-btn>
  
              <v-spacer></v-spacer>


              <v-btn
                  color="blue"
                  text
                  @click="imprimir_pagina()"
                  class="mr-5"
                  x-small
                  v-if="0>1"
              >
                Imprimir
              </v-btn>
  
              <v-btn
                  color="red"
                  text
                  @click="dialog_tabela = false"
              >
                Cancelar
              </v-btn>
              
              &nbsp
  
              <v-btn
                  :disabled="!valido"
                  color="primary"
                  @click="salvar_tabela(tabela_selecionada, item_edita, 'dialog_tabela')"
              >
                Salvar
              </v-btn>
          </v-card-actions>
          </v-card>
      </v-dialog>
  
  
      <v-container>
         <v-row>
            <v-col
              cols="12"
              :md="col_menu"
              class=""
            >
              <v-card
                  class="mx-auto"
              >
                  <v-list         
                    dense
                    nav
                  >
                      <v-list-item-group
                      v-model="selectedItem"
                      color="primary"
                      active-class="blue--text"
                      >
  
                          <v-divider
                              class="mx-0"
                          ></v-divider>
  
                          <!-- passo 2  mostro os items da lista -->
                          <v-list-item
                              v-for="(item, i) in lista_tabelas"
                              :key="i"
                              @click="card_titulo=item.text, referencia=item.referencia, tabela(item.click,'')"
                              @mousemove="col_menu=2"
                          >
                              <v-list-item-icon class="pa-0 mr-2">
                              <v-icon v-text="item.icon" :color="(item.color) ? item.color: ''"></v-icon>
                              </v-list-item-icon>
  
                              <v-list-item-content>
                              <v-list-item-title v-text="item.text"></v-list-item-title>
                              </v-list-item-content>
                          </v-list-item>
  
                          <v-divider
                              class="mx-4"
                          ></v-divider>
  
                      </v-list-item-group>
                  </v-list>
              </v-card>    

              <br>

              <!-- SOMENTE SUPERUSUARIOS -->
              <v-card class="" v-if="superA">
                <v-card-text>
                      <center>
                      <v-btn
                          color="success"
                          small
                          @click="update_server"
                          target="_blank"
                      >
                      <!-- :href="" -->
                          Atualizar App
                      </v-btn>
                    </center>
                </v-card-text>
              </v-card>
              
              <br/>
              <!-- SOMENTE superA -->
              <v-card
               class=""
               v-if="superA"
              >

              <v-container>
                  <v-row>
                    <v-col>
                      <h3>SomenteA</h3>
                      <h6> {{ session_bd }} </h6>
                   </v-col>
                  </v-row>
                  
                  <br/>

                  <v-row>
                    <v-col>
                    <v-textarea
                     v-model="recebe_do_clipboard"
                     label="Clipboard"
                     height="90"
                     background-color="green lighten-5"
                     clearable
                    >
                    </v-textarea>

                    <center>
                      <v-btn
                         color="success"
                         small
                         @click="colar_objetos(tabela_selecionada,'here')"
                      >
                      <!-- :href="" -->
                          Salvar dados
                      </v-btn>
                    </center>

                  </v-col>
                  </v-row>
                </v-container>
                  
              </v-card>

              <br/>

              <v-card class="" v-if="superA">
                <v-card-text>
                      <center>
                      <v-btn
                          color="white"
                          small
                          @click="bomm_app_now"
                          target="_blank"
                      >
                      <!-- :href="" -->
                          Boom App
                      </v-btn>
                    </center>
                </v-card-text>
              </v-card>


            </v-col> 
  
  
            <v-col
             class="grey lighten-5"
             :cols="(isMobile) ? 12 :  12-col_menu"
            >
              <v-card
                class="mx-auto"
              >
              <!-- v-if="items_lista.length > 0" -->

                  <v-card-title
                   class="pa-1 pl-2"
                   v-if="sub_codigo>0"
                  >
                    <!-- <h4 class="primary--text" @click="ultimo_click()" >{{ sub_descricao }} </h4> -->

                    <v-chip
                    color="primary"
                    @click="tabela('DOUTORES',''), card_titulo='Profissionaiss'"
                    v-if="(doutor_selecionado?.NOME)"
                    >
                      {{ doutor_selecionado?.NOME }}
                    </v-chip>

                    &nbsp

                    <v-chip
                    color="primary"
                    @click="tabela('VALORES_AMB',''), card_titulo='Procedimentos'"
                    v-if="(procedimento_selecionado?.CATEGORIA)"
                    >
                      {{ procedimento_selecionado?.CATEGORIA }}
                    </v-chip>

                    &nbsp

                    <!-- <v-chip
                    color="primary"
                    @click="ultimo_click()"
                    >
                      {{ sub_descricao }}
                    </v-chip>
                    &nbsp -->

                    <h5 class="mb-2 grey--text">{{ sub_codigo }} </h5>

                    &nbsp

                    <v-chip
                    color=""
                    
                    >
                    <!-- @click="ultimo_click()" -->
                      VOLTAR
                    </v-chip>

                  </v-card-title>

                  <v-card-title
                    v-if="card_titulo"
                    class="py-0"
                  >
                      <span class="text-h5"> {{ card_titulo }} </span>
                      <span class="red--text mb-2 ml-1"> {{ items_lista.length }} </span>
  
              <span 
                v-if="aguardar_refresh"
              >
               ...
              </span>
  
                      &nbsp 
                      <v-col
                      cols="5"
                      md="2"
                      >
  
                          <v-text-field
                          dense
                          v-model="search"
                          clearable
                          append-icon="mdi-magnify"
                          label="Procurar"
                          single-line                
                          hide-details
                          @keyup.esc="search=''"  
                          >
                          </v-text-field>
  
                      </v-col>
  
                      <v-spacer></v-spacer>
  
                      <v-btn
                          color="primary"
                          small
                          @click="nova_tabela('item_edita','dialog_tabela')"
                          v-if="permissoes(tabela_selecionada, 'novo')"
                      >
                          Novo
                      </v-btn>
                  
                  </v-card-title>
  
                  <v-card-subtitle>
  
                  </v-card-subtitle>
  
                  <v-card-text class="pa-1"
                      v-if="card_titulo"
                  >
                  <div>
                      <v-row>
                          <v-col>
                              <v-card
                                @mouseover="refresh_page"
                              >
                              <!-- passo 5 monto a lista -->
                                  <v-data-table
                                  v-model="selected"
                                  single-select
                                  item-key="CODIGO"
                                  @contextmenu:row="rightClick"
                                  @click:row="seleciona_linha"

                                  :headers="items_header"
                                  :items="items_lista"
                                  class="elevation-1"
                                  :items-per-page="500"
                                  
                                  :mobile-breakpoint="55"
                                  hide-default-footer
                                  :search="search"
                                  dense
                                  :height="(isMobile) ? '' : (items_lista.length>15) ? retorna_altura() : ''"
                                  >

                                      <!-- <template slot="no-data">

                                          <v-btn
                                          color="success"
                                          small
                                          @click="colar_objetos(tabela_selecionada,'clip')"
                                          v-if="superUser"
                                        >
                                           Colar Tudo
                                        </v-btn>

                                      </template> -->


                                      <!-- hide:'S' -->

                                      <!-- PROFISSIONAIS INICIO-->
                                      <template v-slot:item.NOME="{ item }">
                                        <span class="grey--text" v-if="item.ATIVO=='N'"
                                        >  {{ item.NOME }} </span>
                                        <span class="black--text" v-else
                                        > <strong> {{ item.NOME }} </strong> </span>
                                      </template>
                                      
                                      
                                      <template v-slot:item.X_CONVENIO="{ item }">
                                        <strong v-if="item.X_CONVENIO=='TODOS'"> {{ item.X_CONVENIO }} </strong>
                                        <span v-else> {{ item.X_CONVENIO }} </span>
                                      </template>
                                      
                                      <template v-slot:item.DOUBLE_UM="{ item }">
                                        <strong> {{ item.DOUBLE_UM }} </strong>
                                      </template>

                                      <template v-slot:item.ATIVO="{ item ,index }">
                                        <span class="green--text" v-if="item.ATIVO=='S'"
                                        > {{ item.ATIVO }}  {{ index+1 }}
                                    </span>
                                        <span class="red--text" v-else
                                        > {{ item.ATIVO }} </span>
                                      </template>

                                      <template v-slot:item.CRM="{ item }"><strong > {{ item.CRM }} </strong></template>
                                      <!-- PROFISSIONAIS FIM -->


                                      <!-- HORARIOS INICIO -->
                                      <template v-slot:item.VALIDO_DE="{ item }">
                                        <span> {{ getFormatDate(item.VALIDO_DE) }} </span>
                                      </template>
                                      
                                      <template v-slot:item.HI="{ item }">
                                        <strong class="blue--text"> {{ item.HI }} </strong>
                                      </template>
                                      
                                      <template v-slot:item.HF="{ item }">
                                        <strong class="blue--text"> {{ item.HF }} </strong>
                                      </template>
                                      
                                      <template v-slot:item.NOME_AGENDA="{ item }">
                                        <strong> {{ item.NOME_AGENDA }} </strong>
                                      </template>
                                      
                                      <template v-slot:item.ATE="{ item }">
                                        <span class="red--text" v-if="(dif_datas(today, item.ATE)>=0)"
                                        >  <strong> {{ getFormatDate(item.ATE) }} </strong> </span>
                                        <span  v-else
                                        > {{ getFormatDate(item.ATE) }} </span>
                                      </template>

                                      <template v-slot:item.D_2="{ item }"> <strong class="blue--text" v-if="item.D_2=='S'"> {{ item.D_2 }} </strong></template>
                                      <template v-slot:item.D_3="{ item }"> <strong class="blue--text" v-if="item.D_3=='S'"> {{ item.D_3 }} </strong></template>
                                      <template v-slot:item.D_4="{ item }"> <strong class="blue--text" v-if="item.D_4=='S'"> {{ item.D_4 }} </strong></template>
                                      <template v-slot:item.D_5="{ item }"> <strong class="blue--text" v-if="item.D_5=='S'"> {{ item.D_5 }} </strong></template>
                                      <template v-slot:item.D_6="{ item }"> <strong class="blue--text" v-if="item.D_6=='S'"> {{ item.D_6 }} </strong></template>
                                      <template v-slot:item.D_7="{ item }"> <strong class="blue--text" v-if="item.D_7=='S'"> {{ item.D_7 }} </strong></template>
                                      <!-- HORARIOS FIM -->



  
                                      <template v-slot:item.actions="{ item }">
                                          <v-icon
                                              class="mr-2"
                                              @click="editar_tabela(item, 'item_edita', 'dialog_tabela'), table_codigo = item.CODIGO"
                                              color="blue"
                                              :size = "size_mobile"
                                              v-if="permissoes(tabela_selecionada, 'alterar')"
                                          >
                                          mdi-pencil
                                          </v-icon>
                                      </template>
  


                                      <!-- //passo 6 personalizo as acoes dos botoes na lista  INICIO-->
                                      <template v-slot:item.actions_clock="{ item }">
                                        <v-tooltip 
                                              bottom
                                          >
                                              <template v-slot:activator="{ on, attrs }">
                                              <v-icon
                                                  class="mr-2"
                                                  :size = "size_mobile"
                                                  v-bind="attrs"
                                                  v-on="on"
                                                  color="blue"
                                                  @click="tabela('ANTIGO_HORARIO','SUB',item), card_titulo='Horários'"
                                              > 
                                              <!-- :color="item.CONTRATO ? 'blue': 'grey'" -->
                                              mdi-clock-time-four-outline 
                                              </v-icon>
  
                                              </template>
                                              <span> Horários</span>
                                          </v-tooltip>
                                      </template>
                                      
                                      <template v-slot:item.actions_valor="{ item }">
                                        <v-tooltip 
                                              bottom
                                          >
                                              <template v-slot:activator="{ on, attrs }">
                                              <v-icon
                                                  class="mr-2"
                                                  :size = "size_mobile"
                                                  v-bind="attrs"
                                                  v-on="on"
                                                  color="success"
                                                  :disabled="(!superUser)"
                                                  @click="tabela('VALORES_AGENDA','SUB',item), card_titulo='Valores'"
                                              > 
                                              <!-- mdi-credit-card-check -->
                                              mdi-currency-usd
                                              </v-icon>
  
                                              </template>
                                              <span> Valores</span>
                                          </v-tooltip>
                                      </template>
  

                                      
                                      <template v-slot:item.actions_proced="{ item }">
                                        <v-tooltip 
                                              bottom
                                          >
                                              <template v-slot:activator="{ on, attrs }">
                                              <v-icon
                                                  class="mr-2"
                                                  :size = "size_mobile"
                                                  v-bind="attrs"
                                                  v-on="on"
                                                  color="blue"
                                                  @click="tabela('VALORES_AMB','SUB',item), card_titulo='Procedimentos'"
                                              > 
                                              mdi-format-list-checkbox 
                                              </v-icon>
  
                                              </template>
                                              <span> Procedimentos</span>
                                          </v-tooltip>
                                      </template>


                                      <!-- //passo 6 personalizo as acoes dos botoes na lista  FIM -->
  
                                  </v-data-table>
                              </v-card>
  
                          </v-col>
                      </v-row>
  
                  </div>
                  </v-card-text>
              </v-card>
            </v-col>     
         </v-row> 
      </v-container>  
  
    </div>
  </template>
  
  <script>
  
  import axios from "axios";
  import DSirotinas from '../assets/js/DSi_rotinas'//chamo funcao do arquivo
  import DSibasico from '../assets/js/DSi_basico'//chamo funcao do arquivo
  import DSisql from '../assets/js/DSi_sql'//chamo funcao do arquivo
  import { EmojiparaTexto} from '../assets/js/DSi_whats'
  
  var url =  sessionStorage['url']
  
  // defino o dia de hoje
  let a = new Date().toLocaleDateString('pt-Br',{ datestyle: 'short', hour12: false, timeZone: 'America/Sao_Paulo' });//formato dd/mm/yyyy  
  a = a.slice(6, 10) +'-'+ a.slice(3, 5) +'-'+ a.slice(0, 2) ; //formato us yyyy-mm-dd
  var todayy = a;

  
  export default {
      data: () => ({


          obj_todos_convenios :[],
          doutor_selecionado :{},
          procedimento_selecionado :{},
          vai_imprimir: false,

          user_permissoes: {},

          tags_empresa:[],
          tags_farmacias:[],

          obj_copiar : {},
          obj_clicado : {},

          obj_clipboard:[],

          recebe_do_clipboard:'',

          dialog_menu_right: false,

          selected: [],
          clicou_em:'',

          today :'',

          overlay: false,

          session_bd:'',
          table_codigo:'', 
          urlLogo:'',
          urlImages:'',
          files_upload: [],

          col_menu: 2,

          superUser: false,
          superA : false,
          eu_sou : '',

          profissionais:['a','b'],

          selectedItem: -1,

          isMobile: false, 
  
          card_titulo:'',
          referencia:'',
  
          dialog_tabela: false,
          tabela_selecionada:'',
  
          search: '',
  
          aguardar_refresh: false,
  
          size_mobile:22,
          
          lista_tabelas:[],

          sub_codigo:0,
          sub_descricao:'',
  
          items_lista:[],
          items_header: [],
          items_editaveis: [],
          item_edita: {},

          w_data: '99px',
          w_fone: '125px',
          w_cpf: '120px',
          w_cnpj: '150px',
          w_nome: '200px',
          w_situacao: '60px',
          w_valor: '90px',
  
         snackbar: false,
         snack_text: 'My timeout is set to 2000.',
         snack_color: "success",
  
         notnullRules: [
          v => !!v || 'necessário'
         ],

         noRules:[v => true],
  
         valido: false,

         lista_especialidade:[
            'OUTROS',
            'ACUPUNTURA',
            'ALERGIA E IMUNOLOGIA',
            'ANESTESIOLOGIA',
            'ANGIOLOGIA',
            'CANCEROLOGIA',
            'CARDIOLOGIA',
            'CIRURGIA CARDIOVASCULAR',
            'CIRURGIA DA MAO',
            'CIRURGIA DE CABECA E PESCOCO',
            'CIRURGIA DO APARELHO DIGESTIVO',
            'CIRURGIA GERAL',
            'CIRURGIA PEDIATRICA',
            'CIRURGIA PLASTICA',
            'CIRURGIA TORACICA',
            'CIRURGIA VASCULAR',
            'CLINICA MEDICA',
            'COLOPROCTOLOGIA',
            'DERMATOLOGIA',
            'DENTISTA',
            'ENDOCRINOLOGIA E METABOLOGIA',
            'ENDOSCOPIA',
            'ESTETICISTA',
            'GASTROENTEROLOGIA',
            'GENETICA MEDICA',
            'GERIATRIA',
            'GINECOLOGIA',
            'GINECOLOGIA E OBSTETRICIA',
            'HEMATOLOGIA E HEMOTERAPIA',
            'HOMEOPATIA',
            'INFECTOLOGIA',
            'MASTOLOGIA',
            'MEDICINA DE FAMILIA E COMUNIDADE',
            'MEDICINA DO TRABALHO',
            'MEDICINA DE TRAFEGO',
            'MEDICINA ESPORTIVA',
            'MEDICINA FISICA E REABILITACAO',
            'MEDICINA INTENSIVA',
            'MEDICINA LEGAL E PERICIA MEDICA',
            'MEDICINA NUCLEAR',
            'MEDICINA PREVENTIVA E SOCIAL',
            'NEFROLOGIA',
            'NEUROCIRURGIA',
            'NEUROLOGIA',
            'NUTROLOGIA',
            'OFTALMOLOGIA',
            'ORTOPEDIA E TRAUMATOLOGIA',
            'OTORRINOLARINGOLOGIA',
            'PATOLOGIA',
            'PATOLOGIA CLINICA/MEDICINA LABORATORIAL',
            'PEDIATRIA',
            'PERIODONTISTA',
            'PNEUMOLOGIA',
            'PSIQUIATRIA',
            'PSICOLOGIA',
            'RADIOLOGIA E DIAGNOSTICO POR IMAGEM',
            'RADIOTERAPIA',
            'REUMATOLOGIA',
            'UROLOGIA'  
         ]
  
      }),
  

      created(){
        this.today = todayy
        this.logado()
        this.GetPermissoes()
        this.permite_items()
        this.isMobile = (window.innerWidth < 600)
        this.abre_tabelas_necessarias()
        this.onCreated()
      },
  
      methods:{


        async bomm_app_now(){
          //apago o projeto no cliente

          let teste = sessionStorage['url'];
          if (teste == '/api/'){
            alert('Boom não pode ser executado no DEV')
            return false
          }

          if (confirm(`Deseja realmente Excluir o App #1?`)){
            if (confirm(`Deseja realmente Excluir o App#2?`)){
              const res = await  axios.get(url+'boom.php');
              console.log(res.data);
            }
          }



        },


        imprimir_pagina(){

          this.vai_imprimir = true

          setTimeout(function(){
            window.print()
            this.vai_imprimir = false            
          }.bind(this), 111);


        },

        insere_tag(tag){
          // alert(tag)//nao fiz..fazer?
          if (tag){
            // this[clicou_em] = this[clicou_em] + tag
            this.$set(this.item_edita, this.clicou_em, this.item_edita[this.clicou_em] + tag)
          }
        },

        retorna_altura(){
          // let r = '100vh'  
          // let r = '720'
          let r = (window.innerHeight - 80 - 60)
          let height = window.innerHeight;

          return r
        },

        define_tags_key(){

          this.tags_empresa = [
            '@ide',
            '@paciente',
            '@convenio',
            '@profissional',
            '@procedimento',
            '@data_agendamento',
            '@hora_agendamento',
            '@empresa',
            // '@::)',
            // '@;)',
            // '@:)',
            // '@[]',
           ] 

          this.tags_farmacias = [
            '@ide',
            '@paciente',
            '@celular_paciente',
            '@convenio',
            '@profissional',
            '@empresa',
           ] 
                  
        },

        async GetPermissoes(){
          let r = await DSirotinas.GetPermissoes()
          this.user_permissoes = Object.assign({}, r) 
        },

        onCreated(){

          this.session_bd =  'mc_'+ sessionStorage['LSid'];
          this.showDrawer()
          this.ultimo_click()

          this.urlLogo   =  sessionStorage['emp_url_logo']
          this.urlImages =  sessionStorage['emp_url_images']

          this.define_tags_key()
        },

        showDrawer(){
          this.$emit('executar', true)//mostrar ou nao paineis
        },

        async upload_logo(file,pasta,nome,campo_salvar){


            // alert(campo_salvar)
            // console.log(file);


            //update refresh image
            this.urlLogo = URL.createObjectURL(file);

            var bodyFormData = new FormData();
            bodyFormData.append('pasta'       , pasta);
            bodyFormData.append('nome_arquivo', nome);
            bodyFormData.append('userfile'    , file);

            //isso daqui server para ser lido com o $_POST no php 
            const header =   { headers: {'Content-Type': 'multipart/form-data'}}

            // console.log(data);

            const res = await axios.post(url+'upFile.php', bodyFormData, header, )

            // console.log(res.data);

            let r = ''

            // console.log(res.data);

            if (res.data.status == 'OK'){
            
                this.alerta('Atualizado','green')
              
                let tipo = file.type
                tipo = tipo.substr(tipo.indexOf('/')+1,5);
              
                r = ( pasta+'/'+nome+'.'+tipo)

                // sessionStorage['emp_url_logo'] =  sessionStorage['url'] + 'images/'+ r //salvo para ser lido

            }else{
                this.alerta('Ops: '+res.data,'red')
            }

            //salvo no campo editavel no caso dados da empresa
            this.item_edita[campo_salvar] = r

        },



        excluir_tudo(tabela){
          if (confirm(`Deseja realmente Excluir Tudo?`)){
            
            let campo_1 = ''
            let codigo = 0
            if (tabela == 'ANTIGO_HORARIO'){
              campo_1 = 'CODIGO_PROFISSIONAL'
              codigo  = this.sub_codigo
            }
            else if (tabela == 'VALORES_AMB'){
              campo_1 = 'CODIGODOUTOR'
              codigo  = this.sub_codigo
            }
                
            if (codigo>0){

                let sql = `delete from ${tabela}  where ${campo_1} = ${codigo}`
                // console.log(sql);
                this.crud_sql(sql)

                setTimeout(function(){
                    this.ultimo_click()//refresh
                  }.bind(this), 555);
            }

          }

        },

        ultimo_click(){
          let tabela = sessionStorage['tabela_clicou']
          if (tabela){
              this.card_titulo = sessionStorage['tabela_titulo'] 
              this.tabela(tabela,'')
          }
        },

        copiarObj(){

           //clonando objeto
           this.obj_copiar = Object.assign({}, this.obj_clicado)

           //clico no botao novo
           this.nova_tabela('item_edita','dialog_tabela')

           //associo os novos dados
           this.item_edita = Object.assign({}, this.obj_copiar)

           //limpa codigo para adicionar novo
           this.$set(this.item_edita, 'CODIGO', null)

        },

        async to_clipboard(texto, tipo){

          // console.log(texto);
          if (tipo=='obj'){
            this.recebe_do_clipboard = JSON.stringify(texto)
            await navigator.clipboard.writeText( JSON.stringify(texto));
          }else{
            this.recebe_do_clipboard = (texto)
            await navigator.clipboard.writeText( texto);
          }
          
        },


        async colar_objetos(tabela, tipo){

          // quando nao tem https da erro..entao eu uso o 'here' ao inves do 'clip'
          if (tipo=='clip'){
            await this.get_clipboard('obj_clipboard','obj')
          }
          else if (tipo=='here'){
            this.obj_clipboard = await JSON.parse(this.recebe_do_clipboard) //pega e colo num textarea ai usa os dados de la
          }
          

          await this.colar_dados(tabela)
          
        },

        async get_clipboard(para, tipo){
 
          // recebe_do_clipboard
          let recebe = ''

          await navigator.clipboard.readText().then(
            (clipText) => recebe = clipText
            // (clipText) => this.texto_clipboard = clipText
            // (clipText) => document.getElementById("pastle_clipboard").value = clipText //tb funciona mas tem que definir o id
            // (clipText) => document.getElementById("pastle_clipboard").innerText = clipText
          )            


          if (tipo == 'obj'){
               this[para] = JSON.parse(recebe)
          }else{
            this[para] = recebe
          }
        },



        async colar_dados(tabela){

          // console.log('colar_horarios');
          // console.log(this.obj_clipboard);

          let campo_1 = ''
          if (tabela == 'ANTIGO_HORARIO'){
            campo_1 = 'CODIGO_PROFISSIONAL'
          }
          else if (tabela == 'VALORES_AMB'){
            campo_1 = 'CODIGODOUTOR'
          }

          let h = this.obj_clipboard
          for (let i = 0; i < h.length; i++) {
            // muda os campos chaves
             this.$set(this.obj_clipboard[i], 'CODIGO', '0')
             this.$set(this.obj_clipboard[i], campo_1, this.sub_codigo)

             this.salvar_tabela(this.tabela_selecionada, this.obj_clipboard[i], '')
          }

        },

        rightClick(event, item) {
          // console.log(event)
          // console.log(item)

          this.abre_menu_right(item.item)
          event.preventDefault()
        },

        abre_menu_right(item){
          this.dialog_menu_right = true
          // this.item_menu = item

          this.seleciona_linha(item)
        },

        seleciona_linha(row) {
          // console.log('row:');
          // console.log(row);
           this.selected= [{"CODIGO" : row.CODIGO}] //via v-modal no data-table

           //clonando objeto
           this.obj_clicado = Object.assign({}, row)


           if (this.tabela_selecionada == 'DOUTORES'){
               this.doutor_selecionado  = Object.assign({}, row)
           }
           
           else if (this.tabela_selecionada == 'VALORES_AMB'){
               this.procedimento_selecionado  = Object.assign({}, row)
           }
        },

        getVmask(qual){
            
            if (qual == 'cel'){
                return "(##) #####-####"
            }
            else if (qual == 'fone'){
                return "(##) ####-####"
            }
            else if (qual == 'cnpj'){
                return "##.###.###/####-##"
            }
            else if (qual == 'cpf'){
                return "###.###.###-##"
            }
            else if (qual == 'cep'){
                return "#####-###"
            }
            else{
                return ""
            }
        },

        abre_tabelas_necessarias(){
            
            let sql = `select codigo,nome from doutores where ativo = 'S' order by doutores.nome`
            this.crud_abrir_tabela(sql, 'profissionais')
            
            try{ 
             this.obj_todos_convenios  = JSON.parse(sessionStorage['obj_todos_convenios'])

             this.obj_todos_convenios.unshift( { "NOME": "TODOS", "CODIGO": "0" })
            }
            catch (err) {}

        },

        update_server(){

            let url = sessionStorage['url']
            // alert(url)
            url =  url+'upCoptyToRaiz.php?togo='+url
            

            let link = url
            window.open(link, '_blank');      


            this.alerta('Atualizando..', 'blue')

            // 3 segundos ele sai
            setTimeout(function(){
              //saio para atualizar
              this.sair()                  
            }.bind(this), 3000);


        },

        sair(){

          sessionStorage.setItem('logout','S')//manda fazer logout no / = login

          localStorage['reload_page'] = 'S'
          this.$router.push('/')//redireciona para a pagina login

          //quando redireciono pro / ele executa o limpar_session()//zerando dados de login
        } ,

        permite_items(){
            //passo 1 monto os items e suas tabelas e permissoes

            let empresa = sessionStorage['LSEmpresa']
            empresa = empresa.toUpperCase()
            let s = []


            //PEGA NOME DO USUARIO
            this.eu_sou =  DSirotinas.cripto(sessionStorage.getItem('84hfjdyruxy1_is'))
            this.eu_sou = this.eu_sou.trim()



            //PERMISSOES DE USUARIO

              s.push( { text: 'Empresa', icon: 'mdi-home-analytics', click:'CONSULTORIOS' } )

              if (empresa.includes('ODONTORRISO')){//somente se for odontorriso
                s.push( { text: 'Procedimentos Gerais', icon: 'mdi-format-list-checkbox ', click:'OCORRENCIA' } )
              }

              // s.push( { text: 'Modelos de Textos ', icon: 'mdi-file-document-multiple-outline ', click:'ATESTADOS' } )
              // s.push( { text: 'Mensagens do Whats ', icon: 'mdi-whatsapp', click:'CONFIG_WHATS' } )

              // ZAPZAP
              if (sessionStorage['superA']=='S'){
                // s.push( { text: 'Envio de Whats ', icon: 'mdi-whatsapp', click:'CONFIG_WHATS', novo:'N', referencia:'geral' } )
              }



            //super user
            if (sessionStorage.getItem('r0s9i3khepus_is') == 'S' ){
              this.superUser = true //é superusuario
              if (this.eu_sou == 'A'){//é o master A
                  this.superA = true
              }

              s.push( { text: 'Usuários', icon: 'mdi-account-lock  ', click:'USUARIOS' } )
              s.push( { text: 'Bloquear Agenda', icon: 'mdi-calendar-clock', color:'red', click:'BLOQUEAR' } )
            }


            //altera profissionais
            if (sessionStorage.getItem('uTdoc') == 'S' ){
             s.push( { text: 'Profissionais', icon: 'mdi-account-school-outline', click:'DOUTORES' } )
            }
            

            if ((sessionStorage.getItem('set.alteraconvenio') == 'S' )||(this.superUser)){
              s.push(
                 { text: 'Mensagens do Whats ', icon: 'mdi-whatsapp', click:'CONFIG_WHATS' },
                 { text: 'Convênios', icon: 'mdi-home-city ', click:'CONVENIO' }, 
                 { text: 'Farmácias', icon: 'mdi-hospital-box-outline ', click:'FARMACIAS' },
                 { text: 'Log',       icon: 'mdi-file-find-outline', click:'REGISTRO_LOG' } 
              )
            }
            

            this.lista_tabelas = s
        },


        define_defaults(tabela){

            // --------------------DEFINE DATA E HORA-----------------------
            let hoje = new Date().toLocaleDateString('pt-Br',{ datestyle: 'short', hour12: false, timeZone: 'America/Sao_Paulo' });  
            // console.log('hoje:'+ hoje);

            let today = hoje.slice(6, 10) +'-'+ hoje.slice(3, 5) +'-'+ hoje.slice(0, 2) ; //formato us yyyy-mm-dd
            // console.log('today:'+ today);

            let hora = new Date().toLocaleTimeString('pt-Br',{ hour12: false, timeZone: 'America/Sao_Paulo' });
            let hora_curta = hora.slice(0, 5)

            let quem_alteru = sessionStorage['lg:USUARIO']
            quem_alteru = hoje + '_'+hora+'_'+quem_alteru
            // console.log('hora = '+ hora);
            // console.log('hora = '+ hora_curta);
            // --------------------DEFINE DATA E HORA-----------------------


            // passo 7 defino valores default para alguns campos de determinada tabela
           if (tabela=='USUARIOS'){
              //  this.$set(this.item_edita, 'ATIVO', 'S')
               this.$set(this.item_edita, 'SUPERUSER', 'N')
               this.$set(this.item_edita, 'SENHA', '+')
               this.$set(this.item_edita, 'CODIGO_CONVENIO', 0)
               this.$set(this.item_edita, 'AGENDAR', 'S')
               this.$set(this.item_edita, 'ALTERACADASTRO', 'S')

               this.$set(this.item_edita, 'ALTERADOUTORES', 'N')
               this.$set(this.item_edita, 'ALTERASINDICATO', 'N')
               this.$set(this.item_edita, 'DOUTOR', 'N')
               
           }
           else if (tabela=='DOUTORES'){
               this.$set(this.item_edita, 'ATIVO', 'S')
               this.$set(this.item_edita, 'TIPO', 'N')
               this.$set(this.item_edita, 'CODIGOCONSULTORIO', 0)
           }
           else if (tabela=='VALORES_AMB'){
               this.$set(this.item_edita, 'VALOR', 0)
               this.$set(this.item_edita, 'CODIGODOUTOR', this.sub_codigo)
               this.$set(this.item_edita, 'ATIVO', 'S')
           }
           else if (tabela=='VALORES_AGENDA'){
               this.$set(this.item_edita, 'UM'              , 0)
               this.$set(this.item_edita, 'DOUBLE_UM'       , 0)
               this.$set(this.item_edita, 'CODIGO_MEDICO'   , this.doutor_selecionado.CODIGO)
               this.$set(this.item_edita, 'CODIGO_PRO'      , this.procedimento_selecionado.CODIGO)
               this.$set(this.item_edita, 'ALTEROU'         , quem_alteru)
           }
           else if (tabela=='ANTIGO_HORARIO'){
               this.$set(this.item_edita, 'ATIVO', 'S')
               this.$set(this.item_edita, 'TIPO', 'N')
              //  this.$set(this.item_edita, 'ORDEMDECHEGADA', 'N')
               this.$set(this.item_edita, 'CODIGO_PROFISSIONAL', this.sub_codigo)
               this.$set(this.item_edita, 'VALIDO_DE', today)
               this.$set(this.item_edita, 'ATE', '2055-01-01')
               this.$set(this.item_edita, 'INTERVALO', 30)

               this.$set(this.item_edita, 'D_2', 'N')
               this.$set(this.item_edita, 'D_3', 'N')
               this.$set(this.item_edita, 'D_4', 'N')
               this.$set(this.item_edita, 'D_5', 'N')
               this.$set(this.item_edita, 'D_6', 'N')
               this.$set(this.item_edita, 'D_7', 'N')
           }
           else if (tabela=='CONVENIO'){
              this.$set(this.item_edita, 'ATIVO', 'S')
              this.$set(this.item_edita, 'TIPO', 'C')
              this.$set(this.item_edita, 'CODIGOCONSULTORIO', 0)
           }
           
        },


        async before_update(tabela){
          // passo 8 antes de salvar verifico alguns campos necessarios em determinada tabela

          // VERIFICA CAMPOS NUMERICOS QUE NAO PODEM IR VAZIOS
          let VERIFICAR = [
            'USUARIOS|CODIGODOUTOR|0',
            'USUARIOS|CODIGO_CONVENIO|0',
            'VALORES_AMB|DURACAO|0',
            'BLOQUEAR|CODIGO_DOC|0',
            // 'CONFIG_WHATS|MSG_CONFIRMACAO|EMOJI'
          ]
        
          let CAMPOS      = []
          let CAMPO_VER   = ''
          let CAMPO_VALOR = ''
          for (let i = 0; i < VERIFICAR.length; i++) {
             
             CAMPOS      = VERIFICAR[i].split("|");//divide
             CAMPO_VER   = CAMPOS[1]
             CAMPO_VALOR = this.item_edita[CAMPO_VER]

             if (tabela==CAMPOS[0]){//verifica se é a tabela selecionada
                if (!CAMPO_VALOR){//verifica se vazio
                  this.$set(this.item_edita, CAMPOS[1], CAMPOS[2] ) 
                }

                // if (CAMPOS[2] == 'EMOJI'){
                //   alert('EMOJI')
                //   console.log('CAMPO_VALOR:', CAMPO_VALOR);
                //   let CAMPO_VALOR2 = await EmojiparaTexto(CAMPO_VALOR)
                //   console.log('CAMPO_VALOR2:', CAMPO_VALOR2);
                //   this.$set(this.item_edita, CAMPOS[1], CAMPO_VALOR2  )
                // }
             }
          }
        },


        permissoes(tabela, botao){
            
            let r = ''

            if ( (tabela == 'DOUTORES') || (tabela == 'VALORES_AMB') || (tabela == 'ANTIGO_HORARIO') ) {
                r = sessionStorage['uTdoc']
                if (r == 'S'){ return true}else { return false }
            }
            else if (tabela == 'REGISTRO_LOG'){
                return false
            }
            else if ((tabela == 'CONSULTORIOS')||((tabela == 'CONSULTORIOS'))){
                 if (botao == 'novo'){
                    return false
                 }
                 else if (botao == 'alterar'){
                   return this.user_permissoes.superUser
                  // return true
                 }
                
                
            }
            else {
                return true
            }

            
        },

        dif_datas(a,b){
            return DSibasico.dif_datas(a,b)
        },


          //---------------importando funcoes basicas ----------------------- INICIO
          
          alerta(text,color){
              DSibasico.alerta(this, text, color)
          },
  
          moeda(event){
              return DSibasico.moeda(event)
          },

          logado(){
            DSirotinas.logado(this)
          },

          getFormatDate(data){
            let dia =   data.substring(8,10) + '/'+ data.substring(5,7)+ '/'+ data.substring(0,4);//formato a dd/mm/yyyy
            return dia 
          },
  
          //---------------importando funcoes basicas ----------------------- FIM
  
  
  
  
           //---------------importando funcoes sql ----------------------- INICIO
          //EXECUTAR UM SQL TIPO UPDATE
          async crud_sql (sql) {
              await DSisql.crud_sql(this, sql)
          },
  
          async tabela_create(tabela, obj_edita){
              await DSisql.tabela_create(this, tabela, obj_edita)
          },
  
          async tabela_update(tabela, obj_edita){
              await DSisql.tabela_update(this, tabela, obj_edita)
          },
  
          //ABRE OS DADOS DA TABELA
          async crud_abrir_tabela(sql, items_name){
              await DSisql.crud_abrir_tabela(this, sql, items_name)
          }, 
  
          //FICA NO BOTAO SALVAR.. VERIFICA SE UPDATE OU CREATE
          async salvar_tabela(tabela, obj_edita, dialog_name){

              await this.before_update(this.tabela_selecionada)//trato alguns campos necessarios

              await DSisql.salvar_tabela(this,tabela, obj_edita, dialog_name)
          },
  
          async remover_tabela(tabela, obj_edita, titulo){
              await DSisql.remover_tabela(this, tabela, obj_edita, titulo)
          },
  
          async editar_tabela(item, obj_edita_name, dialog_name) {
              this.seleciona_linha(item)
              await DSisql.editar_tabela(this, item, obj_edita_name, dialog_name)
          },
  
          nova_tabela(obj_edita_name,dialog_name){

             DSisql.nova_tabela(this, obj_edita_name,dialog_name)

             //define alguns valores default
             this.define_defaults(this.tabela_selecionada)
          },
  
  
           //---------------importando funcoes sql ----------------------- FIM
  
  
  
  
          refresh_page(){
              // @mouseover="refresh_page" uso isso para atualizar a tabela depois de abrir uma nova janela e fazer alterações la
  
              // console.log('refresh_page:');
              if (this.aguardar_refresh){
                  this.aguardar_refresh = false
  
                  //refresh da tabela selecionada
                  this.tabela(this.tabela_selecionada,'')
              }
          },
  
          async tabela(tabela, acao, item){
            //passo 3 executo a acao quando clicar nos items e monto os campos editaveis e os campos do data-table
  

              // seleciona_linha tem a mesma funcao.. faço isso pra pegar o clientes que foi clicado 
              if (this.tabela_selecionada == 'DOUTORES'){
                 this.doutor_selecionado  = Object.assign({}, item)
                 this.procedimento_selecionado = {}
              }

              if (this.tabela_selecionada == 'VALORES_AMB'){
                 this.procedimento_selecionado  = Object.assign({}, item)
              }

              if (!['DOUTORES','VALORES_AMB','VALORES_AGENDA','ANTIGO_HORARIO',''].includes(tabela)){
                // alert('limpa objetos selecionados')
                this.doutor_selecionado = {}
                this.procedimento_selecionado = {}

                this.sub_codigo = 0
              }


              if ((!item)&&(!acao)){
               sessionStorage['tabela_clicou']   = tabela
               sessionStorage['tabela_titulo']   = this.card_titulo
              }

              this.search = ''//limpa filtro

              this.tabela_selecionada = tabela
              // this.card_titulo = item.text

            //   console.log('item:');
            //   console.log(item);
  
              //limpa array
              this.items_lista       = [] //recebe os dados do sql
              this.items_header      = [] //campos que tem que mostrar no header
              this.items_editaveis   = [] //campos editaveis mas nao tem que mostrar no header


  

              if ((tabela=='CONFIG_WHATS') && (this.referencia == 'geral')){

                 this.items_header = [

                  { text: '..', value: 'actions', sortable: false, type:'hide', cols:'0',width:'30px'},
                  // { text: 'CODIGO', value: 'CODIGO', type:'number', cols:'0' },                    


                  { text: 'Status', value: 'WHATS_SITUACAO',
                        type:'text',
                        tag:'v-select',
                        tag_items:[
                            'INATIVO',         
                            'TESTE',         
                            'ATIVO',
                        ],
                        cols:'2',rule:'S' 
                    },

                  { text: 'Plataforma', value: 'WHATS_PLATAFORMA',
                      type:'text',
                      tag:'v-select',
                      tag_items:[
                          'SECRETARIA_VIRTUAL',         
                          'MEU_PROPRIO_NUMERO',         
                      ],
                      cols:'4',rule:'S' 
                  },
                

                  { text: 'WhatsApp para Testes', value: 'CELULAR_TESTE',tag:'v-text', type:'text', cols:'2',rule:'S',vmask:'cel', width: this.w_fone, hint:'Quando em TESTE, as mensagens serão enviadas para este número' },
                  { text: 'WhatsApp do Atendende', value: 'CELULAR_RESPONDER',tag:'v-text', type:'text', cols:'2',rule:'S',vmask:'cel', width: this.w_fone, hint:'Número que sua empresa utiliza para responder seus clientes' },

                  { text: 'Enviar link do Atendente', value: 'USAR_LINK_ATENDENDE',
                      type:'text',
                      tag:'v-select',
                      tag_items:[
                          'S',         
                          'N',         
                      ],
                      cols:'2',rule:'S' 
                  },

                  { text: '', value: '', type:'number', cols:'10' }, //em branco                   

                  { text: 'API da instância',    value: 'API_LINK',tag:'v-text', type:'text', cols:'12',rule:'N', upper:'N' },
                  { text: 'ID da instância',     value: 'ID',tag:'v-text', type:'text', cols:'6',rule:'N', upper:'N' },
                  { text: 'Token da instância',  value: 'TOKEN',tag:'v-text', type:'text', cols:'6',rule:'N', upper:'N' },

                  { text: 'WebHook',             value: 'WEBHOOK',tag:'v-text', type:'text', cols:'10',rule:'N', upper:'N' },
                  { text: 'ATE',                 value: 'ATE',tag:'v-text', type:'text', cols:'2',rule:'N', upper:'N' },

                  ]




                  // clono os items
                  this.items_editaveis = this.items_header.map((x) => x); //clonando array copiar array

                  // // removo os campos que nao quero visivel somente para edicao
                  this.items_header.splice(this.items_header.findIndex(x => x.value === "API_LINK") , 1);//removo a coluna senha
                  this.items_header.splice(this.items_header.findIndex(x => x.value === "ID") , 1);//removo a coluna senha
                  this.items_header.splice(this.items_header.findIndex(x => x.value === "TOKEN") , 1);//removo a coluna senha
                  this.items_header.splice(this.items_header.findIndex(x => x.value === "WEBHOOK") , 1);//removo a coluna senha
                     


                  let sql = `SELECT *  FROM `+tabela 

                  // console.log(tabela+': '+sql);
                  await this.crud_abrir_tabela(sql, 'items_lista')

              }

              else if (tabela=='ATESTADOS'){


                this.items_header = [

                 { text: '..', value: 'actions', sortable: false, type:'hide', cols:'0',width:'30px'},
                 { text: 'Modelo', value: 'MODELO',tag:'v-text', type:'text', cols:'5',rule:'S', width: this.w_nome, upper:'N' },
                //  { text: 'Texto', value: 'TEXTO',tag:'v-textarea', type:'text', cols:'12', rows:'8',rule:'N', solo:'S' },
                //  { text: 'ANTES', value: 'DESCRICAO',tag:'v-textarea', type:'text', cols:'12', rows:'8',rule:'N', solo:'S' },
                ]

                // clono os items
                this.items_editaveis = this.items_header.map((x) => x); //clonando array copiar array

                // removo os campos que nao quero visivel somente para edicao
                // this.items_header.splice(this.items_header.findIndex(x => x.value === "PARAM_WHATS") , 1);//removo a coluna senha


                let sql = `
                  SELECT codigo, modelo, texto  FROM ${tabela} `

                  // console.log(tabela+': '+sql);
                await this.crud_abrir_tabela(sql, 'items_lista')
              }

              else if (tabela=='CONFIG_WHATS'){

                this.col_menu = 2

                if (item!==undefined){
                  this.sub_codigo = item.CODIGO
                  this.sub_descricao = item.NOME
                }

                this.items_header = [

                { text: '..', value: 'actions', sortable: false, type:'hide', cols:'0',width:'30px'},

                    // { text: 'Parâmetros',tag:'v-card', value: 'PARAM_WHATS', cols:'12' },
                    { text: 'Parâmetros',tag:'v-card', tag: 'v-parametros', value: this.tags_empresa, cols:'12' },
                    
                    { text: 'Mensagem de Confirmação', value: 'MSG_CONFIRMACAO',tag:'v-textarea', type:'text', cols:'6', rows:'8',rule:'N', solo:'S', hint:'Deixe em branco (vazio) para usar o modelo padrão', emoji:'S' },
                    { text: 'Resposta depois de  Confirmado', value: 'RESPOSTA_CONFIRMADO',tag:'v-textarea', type:'text', cols:'6', rows:'8',rule:'N', solo:'S', hint:'Deixe em branco (vazio) para usar o modelo padrão' },//tem q poder salvar emoji

                    { text: 'Aviso via WhatsApp', value: 'MSG_AVISO',tag:'v-textarea', type:'text', cols:'6', rows:'8',rule:'N', solo:'S' },

                    { text: 'Observação Geral (1) na Confirmação', value: 'MSG_OBS',tag:'v-textarea', type:'text', cols:'6', rows:'8',rule:'N', solo:'S' },
                    { text: 'Observação Geral (2) na Confirmação', value: 'MSG_OBS_2',tag:'v-textarea', type:'text', cols:'6', rows:'8',rule:'N', solo:'S' },
                    
                ]

                // clono os items
                this.items_editaveis = this.items_header.map((x) => x); //clonando array copiar array

                // removo os campos que nao quero visivel somente para edicao
                this.items_header.splice(this.items_header.findIndex(x => x.value === "v-parametros") , 1);//removo a coluna senha
                // this.items_header.splice(this.items_header.findIndex(x => x.value === "MSG_OBS") , 1);//removo a coluna senha
                // this.items_header.splice(this.items_header.findIndex(x => x.value === "MSG_AVISO") , 1);//removo a coluna senha


                let sql = `
                  SELECT *  FROM ${tabela} `

                  // console.log(tabela+': '+sql);
                await this.crud_abrir_tabela(sql, 'items_lista')
              }

              else if (tabela=='CONSULTORIOS'){

                this.col_menu = 2

                if (item!==undefined){
                  this.sub_codigo = item.CODIGO
                  this.sub_descricao = item.NOME
                }

                this.items_header = [

                { text: '..', value: 'actions', sortable: false, type:'hide', cols:'0',width:'30px'},

                    { text: 'Nome', value: 'NOME',tag:'v-text', type:'text', cols:'4',rule:'S', width: this.w_nome, upper:'N' },
                    { text: 'Razão Social', value: 'RAZAOSOCIAL',tag:'v-text', type:'text', cols:'5',rule:'S', width: this.w_nome, upper:'N' },
                    { text: 'CNPJ', value: 'CNPJ',tag:'v-text', type:'text', cols:'3',rule:'S',vmask:'cnpj', width: this.w_cnpj },

                    { text: 'Fone', value: 'FONE1',tag:'v-text', type:'text', cols:'2',rule:'N',vmask:'fone', width: this.w_fone },
                    { text: 'WhatsApp', value: 'CELULAR',tag:'v-text', type:'text', cols:'2',rule:'N',vmask:'cel', width: this.w_fone },
                    { text: 'Email', value: 'EMAIL1',tag:'v-text', type:'text', cols:'5',rule:'N' },
                    
                    { text: 'CEP', value: 'CEP',tag:'v-text', type:'text', cols:'3',rule:'N',vmask:'cep' },
                    { text: 'Endereço', value: 'ENDERECO',tag:'v-text', type:'text', cols:'4',rule:'N', upper:'N' },
                    { text: 'Bairro', value: 'BAIRRO',tag:'v-text', type:'text', cols:'3',rule:'N',upper:'N' },
                    { text: 'Cidade', value: 'CIDADE',tag:'v-text', type:'text', cols:'4',rule:'N', upper:'N' },
                    { text: 'UF', value: 'UF',tag:'v-text', type:'text', cols:'1',rule:'N' },
                    // { text: this.session_bd, value: 'PATH_LOGO',tag:'img', type:'img', cols:'12',rule:'N',folder: this.session_bd },
                    { text: 'Logo', value: 'PATH_LOGO',tag:'img', type:'img', cols:'4',rule:'N',folder: this.session_bd, img_name:'logo', img_name_field:'' },
                    // { text: 'Logo', value: 'PATH_LOGO',tag:'v-text', type:'text', cols:'4',rule:'N' },

                    { text: 'Dados do Diretor Técnico', value: 'DIRETOR_TECNICO',tag:'v-textarea', type:'text', cols:'5', rows:'4',rule:'N' },
                    


                    { text: 'Observação da Confirmação de Consulta via WhatsApp', value: 'MSG_WHATS',tag:'v-textarea', type:'text', cols:'6', rows:'8',rule:'N', solo:'S' },
                    { text: 'Aviso via WhatsApp', value: 'MSG_WHATS_2',tag:'v-textarea', type:'text', cols:'6', rows:'8',rule:'N', solo:'S' },
                ]



                if (this.superA){
                  
                    this.items_header .push( 
                      { text: 'Agendar Online', value: 'AGENDAR_ONLINE',
                        type:'text',
                        tag:'v-select',
                        tag_items:[
                            'S',         
                            'N',         
                        ],
                        cols:'2',rule:'N' 
                      },

                     )
                }


                // clono os items
                this.items_editaveis = this.items_header.map((x) => x); //clonando array copiar array

                // removo os campos que nao quero visivel somente para edicao
                this.items_header.splice(this.items_header.findIndex(x => x.value === "MSG_WHATS") , 1);//removo a coluna senha
                this.items_header.splice(this.items_header.findIndex(x => x.value === "MSG_WHATS_2") , 1);//removo a coluna senha

                this.items_header.splice(this.items_header.findIndex(x => x.value === "PATH_LOGO") , 1);//removo a coluna senha
                this.items_header.splice(this.items_header.findIndex(x => x.value === "DIRETOR_TECNICO") , 1);//removo a coluna senha
                this.items_header.splice(this.items_header.findIndex(x => x.value === "RAZAO_SOCIAL") , 1);//removo a coluna senha
                this.items_header.splice(this.items_header.findIndex(x => x.value === "BAIRRO") , 1);//removo a coluna senha
                this.items_header.splice(this.items_header.findIndex(x => x.value === "CIDADE") , 1);//removo a coluna senha
                this.items_header.splice(this.items_header.findIndex(x => x.value === "UF") , 1);//removo a coluna senha
                this.items_header.splice(this.items_header.findIndex(x => x.value === "CEP") , 1);//removo a coluna senha

                let sql = `
                  SELECT codigo, nome, razaosocial, cnpj, fone1, celular, email1, cep, endereco, bairro, cidade,uf, MSG_WHATS, MSG_WHATS_2, PATH_LOGO, DIRETOR_TECNICO, AGENDAR_ONLINE  FROM ${tabela} `

                  // console.log(tabela+': '+sql);
                await this.crud_abrir_tabela(sql, 'items_lista')
              }

              
              else if (tabela=='USUARIOS'){

                this.items_header = [

                    { text: '..', value: 'actions', sortable: false, type:'hide', cols:'0',width:'30px'},
                    // { text: 'CODIGO', value: 'CODIGO', tag:'v-text', type:'number', cols:'0' },
                    { text: 'Usuário', value: 'USUARIO', tag:'v-text', type:'text', cols:'3',rule:'S' },
                    { text: 'CPF', value: 'CPF_WEB', tag:'v-text', type:'text', cols:'2',rule:'S' },
                    { text: 'Super', value: 'SUPERUSER', type:'text',tag:'v-select',tag_items:['S','N'],cols:'1',rule:'S'},
                ]
                
                this.items_editaveis = [

                    { text: '..', value: 'actions', sortable: false, type:'hide', cols:'0',width:'30px'},

                    // { text: 'CODIGO', value: 'CODIGO', tag:'v-text', type:'number', cols:'0' },
                    { text: 'Usuário', value: 'USUARIO', tag:'v-text', type:'text', cols:'3',rule:'S' },
                    { text: 'Senha', value: 'SENHA_WEB', tag:'v-text', type:'password', cols:'2',rule:'S', hide:'S' },
                    { text: 'CPF', value: 'CPF_WEB', tag:'v-text', type:'text', cols:'2',rule:'N' },
                    { text: 'E-mail', value: 'EMAIL', tag:'v-text', type:'text', cols:'5',rule:'N' },

                    { text: 'Profissional', value: 'CODIGODOUTOR',
                          type:'text',
                          tag:'v-select',
                          tag_items: this.profissionais,
                          tag_clear:'S',
                          cols:'6' ,
                          hint:'Definir o Profissional ou deixar em branco'
                    },

                    { text: 'Convênio', value: 'CODIGO_CONVENIO',
                          type:'text',
                          tag:'v-select',
                          tag_items: this.obj_todos_convenios,
                          tag_clear:'S',
                          cols:'6' ,
                          hint:'Definir um Convênio ou deixar em branco'
                    },

                    { text: 'Super', value: 'SUPERUSER', type:'text',tag:'v-select',tag_items:['S','N'],cols:'1',rule:'S', class:'red--text', hint:'Superusuário do Sistema'},

                    { text: 'Agendar', value: 'AGENDAR', type:'text',tag:'v-select',tag_items:['S','N'],cols:'1',rule:'S', hint:'Faz Agendamento'},
                    { text: 'Cadastrar', value: 'ALTERACADASTRO', type:'text',tag:'v-select',tag_items:['S','N'],cols:'1',rule:'S', hint:'Faz Cadastro de Paciente'},
                    
                    { text: 'Alt.Doc', value: 'ALTERADOUTORES', type:'text',tag:'v-select',tag_items:['S','N'],cols:'1',rule:'S', hint:'Altera Tabela de Profissionais'},
                    { text: 'Alt.Conv', value: 'ALTERASINDICATO', type:'text',tag:'v-select',tag_items:['S','N'],cols:'1',rule:'S', hint:'Altera Tabela de Convênios'},
                    // { text: 'Atende', value: 'DOUTOR', type:'text',tag:'v-select',tag_items:['S','N'],cols:'1',rule:'S', hint:'Abre Atendimento, Receitas, Exames, Atestados, mas não acessa histórico'},
                    { text: 'Atende', value: 'ABRE_ATENDIMENTO', type:'text',tag:'v-select',tag_items:['S','N'],cols:'1',rule:'S', hint:'Abre Atendimento, Receitas, Exames, Atestados, mas não acessa Histórico nem Evolução'},
                    { text: 'Evolução', value: 'VER_EVOLUCAO', type:'text',tag:'v-select',tag_items:['S','N'],cols:'1',rule:'S', hint:'Acessa Evolução na janela de Atendimento'},
                    { text: 'Histórico', value: 'VEHISTORICO', type:'text',tag:'v-select',tag_items:['S','N'],cols:'1',rule:'S', hint:'Acessa Histórico de Evolução no Atendimento e no Cadastro'},



                ]

                if (this.superA){//show only A user
                  this.items_editaveis.push( { text: 'Chave', value: 'SENHA_WEB', tag:'v-text', type:'text', cols:'2',rule:'S', hide:'S' })
                }

                // this.items_header = this.items_editaveis//clonando do jeito ERRADO
                this.items_header = this.items_editaveis.map((x) => x); //clonando array copiar array
        

                this.items_header.splice(this.items_header.findIndex(x => x.value === "SENHA_WEB") , 1);//removo a coluna senha
                this.items_header.splice(this.items_header.findIndex(x => x.value === "SENHA_WEB") , 1);//removo a coluna senha = chave
                


                //nao busca o A..somente A pode ver A
                let adendo = ''
                if (!this.superA){
                  adendo =` where usuario<>'A'`    
                }

                let sql = `SELECT 
                 codigo,usuario,senha_web,superuser,cpf_web, alteradoutores,codigodoutor,alterasindicato,doutor, agendar, alteracadastro, email, CODIGO_CONVENIO, VEHISTORICO, ABRE_ATENDIMENTO, VER_EVOLUCAO
                 FROM USUARIOS `
                 +adendo+
                 `
                 ORDER BY USUARIO`

                  // console.log('usuarios: '+sql);
                  await this.crud_abrir_tabela(sql, 'items_lista')
              }
              
              else if (tabela=='BLOQUEAR'){


                  // if (item!==undefined){
                  //   this.sub_codigo = item.CODIGO
                  //   this.sub_descricao = item.NOME
                  // }

                  this.items_header = [

                    //   { text: 'CODIGO', value: 'CODIGO', tag:'v-text', type:'number', cols:'2' },
                      { text: '..', value: 'actions', sortable: false, type:'hide', cols:'0',width:'30px'},

                      { text: 'Descrição', value: 'MOTIVO', tag:'v-text', type:'text', cols:'4',rule:'S' },
                      { text: 'Profissional', value: 'X_PROFISSIONAL', tag:'v-text', type:'text', cols:'4',rule:'S' },
                      
                      { text: 'Profissional', value: 'CODIGO_DOC',
                          type:'text',
                          tag:'v-select',
                          tag_items: this.profissionais,
                          tag_clear:'S',
                          cols:'4' ,
                          hint:'Deixar em Branco se for para Todos'
                      },

                      { text: 'De', value: 'DATA_DE', tag:'v-text', type:'date', cols:'2',rule:'S' },
                      { text: 'Ate', value: 'DATA_ATE', tag:'v-text', type:'date', cols:'2',rule:'S' },

                      { text: 'De', value: 'x_DATA_DE', tag:'v-text', type:'date', cols:'2',rule:'S' },
                      { text: 'Ate', value: 'x_DATA_ATE', tag:'v-text', type:'date', cols:'2',rule:'S' },

                  ]


                  this.items_editaveis = this.items_header.map((x) => x); //clonando array copiar array


                  // REMOVO DA EDIÇÃO
                  this.items_editaveis.splice(this.items_editaveis.findIndex(x => x.value === "X_PROFISSIONAL") , 1);//removo a coluna senha
                  this.items_editaveis.splice(this.items_editaveis.findIndex(x => x.value === "x_DATA_DE") , 1);//removo a coluna senha
                  this.items_editaveis.splice(this.items_editaveis.findIndex(x => x.value === "x_DATA_ATE") , 1);//removo a coluna senha

                  // REMOVO DA LISTA
                  this.items_header.splice(this.items_header.findIndex(x => x.value === "CODIGO_DOC") , 1);//removo a coluna senha
                  this.items_header.splice(this.items_header.findIndex(x => x.value === "DATA_DE") , 1);//removo a coluna senha
                  this.items_header.splice(this.items_header.findIndex(x => x.value === "DATA_ATE") , 1);//removo a coluna senha





                  let sql = `SELECT
                              BLOQUEAR.CODIGO,
                              BLOQUEAR.CODIGO_DOC,
                              BLOQUEAR.DATA_DE,
                              BLOQUEAR.DATA_ATE,
                              BLOQUEAR.MOTIVO,
                              BLOQUEAR.QUEM_ALTEROU,
                              CASE
                                WHEN BLOQUEAR.CODIGO_DOC IS NULL OR BLOQUEAR.CODIGO_DOC = 0 THEN 'TODOS'
                                ELSE DOUTORES.NOME
                              END AS X_PROFISSIONAL
                            FROM
                            BLOQUEAR
                            LEFT JOIN DOUTORES ON (BLOQUEAR.CODIGO_DOC = DOUTORES.CODIGO)
                            ORDER BY BLOQUEAR.DATA_DE;
                            `

                  // console.log('horarios: '+sql);
                await this.crud_abrir_tabela(sql, 'items_lista')
              }
              
              else if (tabela=='ANTIGO_HORARIO'){


                  if (item!==undefined){
                    this.sub_codigo = item.CODIGO
                    this.sub_descricao = item.NOME
                  }

                  this.items_header = [

                    //   { text: 'CODIGO', value: 'CODIGO', tag:'v-text', type:'number', cols:'2' },
                      { text: '..', value: 'actions', sortable: false, type:'hide', cols:'0',width:'30px'},

                      { text: 'Descrição', value: 'NOME_AGENDA', tag:'v-text', type:'text', cols:'3',rule:'S' },
                      { text: 'Inicio', value: 'HI', tag:'v-text', type:'time', cols:'2',rule:'S' },
                      { text: 'Fim', value: 'HF', tag:'v-text', type:'time', cols:'2',rule:'S' },
                      { text: 'Duração', value: 'INTERVALO', tag:'v-text', type:'number', cols:'1',rule:'S' },
                      { text: 'De', value: 'VALIDO_DE', tag:'v-text', type:'date', cols:'2',rule:'S' },
                      { text: 'Ate', value: 'ATE', tag:'v-text', type:'date', cols:'2',rule:'S' },
                    //   { text: 'CP', value: 'CODIGO_PROFISSIONAL', tag:'v-text', type:'numer', cols:'2',rule:'S' },

                      { text: 'Seg', value: 'D_2', type:'text',tag:'v-select',tag_items:['S','N'],cols:'1',rule:'S'},
                      { text: 'Ter', value: 'D_3', type:'text',tag:'v-select',tag_items:['S','N'],cols:'1',rule:'S'},
                      { text: 'Qua', value: 'D_4', type:'text',tag:'v-select',tag_items:['S','N'],cols:'1',rule:'S'},
                      { text: 'Qui', value: 'D_5', type:'text',tag:'v-select',tag_items:['S','N'],cols:'1',rule:'S'},
                      { text: 'Sex', value: 'D_6', type:'text',tag:'v-select',tag_items:['S','N'],cols:'1',rule:'S'},
                      { text: 'Sab', value: 'D_7', type:'text',tag:'v-select',tag_items:['S','N'],cols:'1',rule:'S'},

                      { text: 'Agendar Online', value: 'AGENDAR_ONLINE',
                            type:'text',
                            tag:'v-select',
                            tag_items:[
                                'S',         
                                'N',         
                            ],
                            cols:'2',rule:'N' 
                      },
                  ]

                  this.items_editaveis = this.items_header  //campos editaveis sao os mesmos do header


                  let sql = `
                    SELECT codigo,codigo_profissional, nome_agenda, hi, hf, intervalo, valido_de, ate, d_2, d_3, d_4, d_5, d_6, d_7, ativo, tipo, AGENDAR_ONLINE
                      FROM
                    ANTIGO_HORARIO
                      WHERE
                    (ANTIGO_HORARIO.CODIGO_PROFISSIONAL = ${this.sub_codigo}) 
                    order by
                    ate desc, d_2 desc, d_3 desc, d_4 desc, d_5 desc, d_6 desc, d_7 desc ,hi, hf, nome_agenda
                  `

                //   console.log('horarios: '+sql);
                await this.crud_abrir_tabela(sql, 'items_lista')
              }

              else if (tabela=='DOUTORES'){


                  //limpa campos sub
                  this.sub_codigo    = 0
                  this.sub_descricao = ''


                  this.items_header = []

                  this.items_editaveis = [

                      { text: '..', value: 'actions', sortable: false, type:'hide', cols:'0',width:'5px'},
                      { text: 'Horários', value: 'actions_clock', sortable: false, type:'hide', cols:'0',width:'5px'},
                      { text: 'Proced.', value: 'actions_proced', sortable: false, type:'hide', cols:'0',width:'5px'},

                      { text: 'Codigo', value: 'CODIGO', type:'number', cols:'0' },
                      { text: 'Ativo', value: 'ATIVO',type:'text',tag:'v-select',tag_items:['S','N'],cols:'1'},

                      { text: 'Agendar Online', value: 'AGENDAR_ONLINE',
                            type:'text',
                            tag:'v-select',
                            tag_items:[
                                'S',         
                                'N',         
                            ],
                            cols:'2',rule:'N' 
                      },

                      { text: 'Nome', value: 'NOME', tag:'v-text', type:'text', cols:'5',rule:'S' },

                      { text: 'Especialidade', value: 'CATEGORIA',
                          type:'text',
                          tag:'v-autocomplete',
                          tag_items: this.lista_especialidade,
                          cols:'4' 
                      },

                      { text: 'Conselho', value: 'CNES_PF',
                          type:'text',
                          tag:'v-select',
                          tag_items:['CRM', 'CRO', 'CRP','-'],
                          cols:'2' ,
                          rule:'S'
                      },
                      
                      { text: 'UF', value: 'UF', tag:'v-text', type:'text', cols:'1',rule:'N' },
                      { text: 'CRM', value: 'CRM', tag:'v-text', type:'text', cols:'1',rule:'S' },
                      { text: 'CPF', value: 'CPF', tag:'v-text', type:'text', cols:'2',rule:'S' },
                      { text: 'Celular', value: 'CELULAR', tag:'v-text', type:'text', cols:'2',rule:'N' },
                      { text: 'Email', value: 'EMAIL', tag:'v-text', type:'text', cols:'4',rule:'N' },

                      { text: 'Endereço', value: 'ENDERECO', tag:'v-text', type:'text', cols:'6',rule:'N' },
                      { text: 'Cidade - PR', value: 'CIDADE_UF', tag:'v-text', type:'text', cols:'4',rule:'N' },
                      { text: 'Fone', value: 'FONE2', tag:'v-text', type:'text', cols:'2',rule:'N' },

                      { text: 'Logo Doctor', value: 'PATH_LOGO',tag:'img', type:'img', cols:'4',rule:'N',folder: this.session_bd+'/logo_doctors', img_name:'logo', img_name_field:'CODIGO' },
                    // { text: 'Logo', value: 'PATH_LOGO',tag:'img', type:'img', cols:'12',rule:'N',folder:'mc_350/folder_doc' },

                      { text: 'Título nos Atestados e impressões', value: 'ATESTADO_TITULO',tag:'v-textarea', type:'text', cols:'5', rows:'3',rule:'N' },

                  ]


                  this.items_header = this.items_editaveis.map((x) => x); //clonando array copiar array
        

                  this.items_header.splice(this.items_header.findIndex(x => x.value === "PATH_LOGO") , 1);//removo a coluna senha
                  this.items_header.splice(this.items_header.findIndex(x => x.value === "ATESTADO_TITULO") , 1);//removo a coluna senha = chave
                    


                  

                  let sql = `select codigo,ativo, nome, cpf, email, celular, fone1, categoria,crm, cnes_pf,uf, crm, codigoconsultorio, path_logo, atestado_titulo, cidade_uf, endereco, fone2, AGENDAR_ONLINE from doutores WHERE (ativo <> 'X') order by ativo desc, NOME asc`

                //   console.log('doutores: '+sql);
                await this.crud_abrir_tabela(sql,'items_lista')
              }
  
              else if (tabela=='VALORES_AGENDA'){

                  if (item!==undefined){
                    this.sub_codigo      = item.CODIGO
                    this.sub_descricao   = item.CATEGORIA
                    // console.log('item:', item);
                  }

                  this.items_header = [

                    { text: '..', value: 'actions', sortable: false, type:'hide', cols:'0',width:'30px'},
                    // { text: 'Valores', value: 'actions_valor', sortable: false, type:'hide', cols:'0',width:'5px'},

                    //   { text: 'CODIGO', value: 'CODIGO', tag:'v-text', type:'number', cols:'2' },
                      { text: 'Convênio', value: 'X_CONVENIO', tag:'v-text', type:'text', cols:'6',rule:'S', width:'250px' },
                      { text: 'Convênio', value: 'CODIGO_CONVENIO',
                          type:'text',
                          tag:'v-select',
                          tag_items: this.obj_todos_convenios,
                          tag_clear:'S',
                          cols:'5' ,
                          hint:'',
                          rule:'S'
                      },

                      { text: 'Valor', value: 'DOUBLE_UM', tag:'v-text', type:'text', cols:'2',rule:'N' },
                      

                  ]

                  this.items_editaveis = this.items_header //campos editaveis sao os mesmos do header


                  // clono os items
                  this.items_editaveis = this.items_header.map((x) => x); //clonando array copiar array

                  // removo da edicao
                  this.items_editaveis.splice(this.items_editaveis.findIndex(x => x.value === "X_CONVENIO") , 1);//removo a coluna senha
                  
                  // removo do header
                  this.items_header.splice(this.items_header.findIndex(x => x.value === "CODIGO_CONVENIO") , 1);//removo a coluna senha



                  let sql = `

                    SELECT
                      CASE
                          WHEN VALORES_AGENDA.CODIGO_CONVENIO = 0 THEN 'TODOS'
                          ELSE COALESCE(CONVENIO.NOME, 'CONVENIO REMOVIDO!')
                      END AS x_CONVENIO,

                      VALORES_AGENDA.CODIGO,
                      VALORES_AGENDA.CODIGO_MEDICO,
                      VALORES_AGENDA.CODIGO_PRO,
                      VALORES_AGENDA.CODIGO_CONVENIO,
                      VALORES_AGENDA.DOUBLE_UM,
                      VALORES_AGENDA.DOUBLE_DOIS,
                      VALORES_AGENDA.ALTEROU

                    FROM
                    VALORES_AGENDA
                    LEFT OUTER JOIN CONVENIO ON (VALORES_AGENDA.CODIGO_CONVENIO=CONVENIO.CODIGO)
                    WHERE
                      (VALORES_AGENDA.CODIGO_PRO = ${this.sub_codigo})
                    ORDER BY
                    VALORES_AGENDA.CODIGO_CONVENIO
                  `

                  // console.log('horarios: '+sql);
                await this.crud_abrir_tabela(sql, 'items_lista')
              }
  
              else if (tabela=='VALORES_AMB'){


                  // if (item!==undefined){
                    // this.sub_codigo = item.CODIGO
                    this.sub_codigo    = this.doutor_selecionado.CODIGO 
                    this.sub_descricao = this.doutor_selecionado.NOME
                  // }

                  this.items_header = [

                    { text: '..', value: 'actions', sortable: false, type:'hide', cols:'0',width:'30px'},
                    { text: 'Valores', value: 'actions_valor', sortable: false, type:'hide', cols:'0',width:'5px'},

                    //   { text: 'CODIGO', value: 'CODIGO', tag:'v-text', type:'number', cols:'2' },
                      { text: 'Descrição', value: 'CATEGORIA', tag:'v-text', type:'text', cols:'6',rule:'S', width:'300px' },

                      { text: 'Agendar Online', value: 'AGENDAR_ONLINE',
                        type:'text',
                        tag:'v-select',
                        tag_items:[
                            'S',         
                            'N',         
                        ],
                        cols:'2',rule:'N' 
                      },

                    //   { text: 'Duração', value: 'DURACAO', tag:'v-text', type:'text', cols:'2',rule:'N' },
                    { text: 'Nota via WhatsApp de Confirmação de Consulta', value: 'WHATS_OBS',tag:'v-textarea', type:'text', cols:'4', rows:'8',rule:'N', solo:'S' },

                  ]

                  this.items_editaveis = this.items_header //campos editaveis sao os mesmos do header

                  let sql = `
                  select  codigo, categoria, duracao, codigodoutor, valor, whats_obs, AGENDAR_ONLINE
                  from valores_amb
                  where
                    (valores_amb.codigodoutor = ${this.sub_codigo}) and  ((valores_amb.ativo = 'S') or  (valores_amb.ativo is null))
                  order by    valores_amb.categoria
                  `

                //   console.log('horarios: '+sql);
                await this.crud_abrir_tabela(sql, 'items_lista')
              }


              else if (tabela=='CONVENIO'){

                  if (item!==undefined){
                    this.sub_codigo = item.CODIGO
                    this.sub_descricao = item.NOME
                  }

                  this.items_header = [

                  { text: '..', value: 'actions', sortable: false, type:'hide', cols:'0',width:'30px'},
                    //   { text: 'CODIGO', value: 'CODIGO', tag:'v-text', type:'number', cols:'2' },
                      { text: 'Ativo', value: 'ATIVO',type:'text',tag:'v-select',tag_items:['S','N'],cols:'2', rule:'S',width:'30px'},
                      { text: 'Descrição', value: 'NOME', tag:'v-text', type:'text', cols:'6',rule:'S' },
                      { text: 'Agendar Online', value: 'AGENDAR_ONLINE',
                            type:'text',
                            tag:'v-select',
                            tag_items:[
                                'S',         
                                'N',         
                            ],
                            cols:'2',rule:'N' 
                      },
                  ]

                  this.items_editaveis = this.items_header //campos editaveis sao os mesmos do header

                  let sql = `
                    SELECT codigo, ativo, nome, codigoconsultorio, AGENDAR_ONLINE FROM CONVENIO  WHERE ativo = 'S' order by ativo desc, nome `

                //   console.log('horarios: '+sql);
                await this.crud_abrir_tabela(sql, 'items_lista')
              }

              else if (tabela=='REGISTRO_LOG'){

                  if (item!==undefined){
                    this.sub_codigo = item.CODIGO
                    this.sub_descricao = item.NOME
                  }

                  this.items_header = [

                      { text: '..', value: 'actions', sortable: false, type:'hide', cols:'0',width:'30px'},
                      { text: 'DATA', value: 'DATA', tag:'v-text', type:'date', cols:'2',rule:'N' },
                      { text: 'HORA', value: 'HORAS', tag:'v-text', type:'text', cols:'2',rule:'N' },
                      { text: 'Descrição', value: 'DESCRICAO', tag:'v-text', type:'text', cols:'6',rule:'S' },
  
                  ]

                  this.items_editaveis = this.items_header //campos editaveis sao os mesmos do header

                  let sql = `
                    SELECT * from ${tabela} order by DATA desc, HORAS  desc ROWS 1 TO 100;`

                  console.log(sql);
                await this.crud_abrir_tabela(sql, 'items_lista')
              }

              else if (tabela=='FARMACIAS'){

                  if (item!==undefined){
                    this.sub_codigo = item.CODIGO
                    this.sub_descricao = item.NOME
                  }

                  this.items_header = [

                  { text: '..', value: 'actions', sortable: false, type:'hide', cols:'0',width:'30px'},
                    //   { text: 'CODIGO', value: 'CODIGO', tag:'v-text', type:'number', cols:'2' },
                      { text: 'Ativo', value: 'ATIVO',type:'text',tag:'v-select',tag_items:['S','N'],cols:'2', rule:'S',width:'30px'},
                      { text: 'Nome', value: 'NOME', tag:'v-text', type:'text', cols:'7',rule:'S' },
                      { text: 'WhatsApp', value: 'NUMERO',tag:'v-text', type:'text', cols:'3',rule:'S',vmask:'cel', width: this.w_fone },
                      { text: 'Descrição', value: 'DESCRICAO', tag:'v-text', type:'text', cols:'12',rule:'N' },


                      { text: 'Parâmetros',tag:'v-card', tag: 'v-parametros', value: this.tags_farmacias, cols:'12' },
                      { text: 'Mensagem no Whats', value: 'MENSAGEM_WHATS',tag:'v-textarea', type:'text', cols:'6', rows:'8',rule:'S', solo:'S', hint:'Aqui fica a mensagem que será enviada junto com a receita via WhatsApp' },

                      
                  ]

                  // clono os items
                  this.items_editaveis = this.items_header.map((x) => x); //clonando array copiar array

                  // removo os campos que nao quero visivel somente para edicao
                  this.items_header.splice(this.items_header.findIndex(x => x.value === "v-parametros") , 1);//removo a coluna senha

                  let sql = `select * from ${tabela} order by ativo, nome`

                //   console.log('horarios: '+sql);
                await this.crud_abrir_tabela(sql, 'items_lista')
              }

              else if (tabela=='OCORRENCIA'){

                  if (item!==undefined){
                    this.sub_codigo = item.CODIGO
                    this.sub_descricao = item.NOME
                  }

                  this.items_header = [

                  { text: '..', value: 'actions', sortable: false, type:'hide', cols:'0',width:'30px'},
                    //   { text: 'CODIGO', value: 'CODIGO', tag:'v-text', type:'number', cols:'2' },
                      // { text: 'Ativo', value: 'ATIVO',type:'text',tag:'v-select',tag_items:['S','N'],cols:'2', rule:'S',width:'30px'},
                      { text: 'Descrição', value: 'DESCRICAO', tag:'v-text', type:'text', cols:'7',rule:'S' },
                      { text: 'Alerta no Agendamento', value: 'ALERTA', tag:'v-text', type:'text', cols:'5',rule:'N',upper:'N' },
                  ]

                  this.items_editaveis = this.items_header //campos editaveis sao os mesmos do header

                  let sql = `
                    SELECT * from OCORRENCIA order by descricao`

                //   console.log('horarios: '+sql);
                await this.crud_abrir_tabela(sql, 'items_lista')
              }



          },
  
          allUpper(event, field){
              let a = event.target.value;//captura o valor do event
              if (a!==''){  
                  a = a.toUpperCase();
                  this.item_edita[field] = a;
              } 
          },
          
          firstUpper(event, field){
              // console.log(event);
              let a = event.target.value;//captura o valor do event
              if (a!=='' && a.length==1){  
                a = a.toUpperCase();
                this.item_edita[field] = a;
              } 
          },
  
  
  
          meu_keyup(event,campo,upper){
  

            //identifica se o campo é double e muda o display
            if ((campo.includes('double_') || campo.includes('VALOR')) ){
              this.item_edita[campo] =  this.moeda(event)
              // @keyup="editedItem.double_credito = moeda($event)"
            }
            else if (campo.includes('CEP')) {

              this.consulta_cep(this.item_edita[campo])    
            }
            else{
              if ((campo.includes('EMAIL')==false) &&
                (campo.includes('SENHA')==false) &&
                (campo.includes('SENHA_WEB')==false)){

                  if (upper !=='N'){
                    this.allUpper(event, campo)
                  }
                  
              }
            }
          },

          async consulta_cep(cep){

            if (cep?.length>=9){

              let link = "https://viacep.com.br/ws/"+cep+"/json/"
              const res = await axios.get(link);
              // console.log(res.data);

              this.$set(this.item_edita, 'ENDERECO', res.data.logradouro.toUpperCase())
              this.$set(this.item_edita, 'BAIRRO',   res.data.bairro.toUpperCase())
              this.$set(this.item_edita, 'CIDADE',   res.data.localidade.toUpperCase())
              this.$set(this.item_edita, 'UF',       res.data.uf.toUpperCase())

            }
          } , 
  
 
   
      }
  }
  </script>
  
  <style scoped>
  /* <style scoped> */
    .container{
      max-width: 100%;
    }
  
    .v-tab{
    font-size: 9pt; /* usando o sass-variables */
   }
  
  .count_item {
    position: relative !important;
    top: -5px;
    font-size: 80%;
    vertical-align: super;
    color: red;
  }

  /* tamanho da fonte do data-table */
.v-data-table >>> tr>td{
  font-size:12px !important;
  /* height: 25px !important; */
}

/* tamanho da fonte do data-table */

.theme--light.v-data-table tbody tr.v-data-table__selected {
    /* seleciona_linha cor */
    background: #eee9e9 !important;
    /* background: blue !important; */
}

  </style>